import React from "react";
import "./DiseaseInformation.css";

const DiseaseInformation = () => {
  const rheumatoid = [
    "https://www.mayoclinic.org/diseases-conditions/rheumatoid-arthritis/symptoms-causes/syc-20353648",
    "https://my.clevelandclinic.org/health/diseases/4924-rheumatoid-arthritis",
    "https://www.arthritis.org/diseases/rheumatoid-arthritis",
    "https://www.niams.nih.gov/health-topics/rheumatoid-arthritis/basics/symptoms-causes",
    "https://www.who.int/news-room/fact-sheets/detail/Rheumatoid-arthritis",
  ];
  const psoriatic = [
    "https://www.arthritis.org/diseases/psoriatic-arthritis",
    "https://www.hopkinsmedicine.org/health/conditions-and-diseases/arthritis/psoriatic-arthritis",
    "https://my.clevelandclinic.org/health/diseases/13286-psoriatic-arthritis",
    "https://www.psoriasis.org/about-psoriatic-arthritis/",
    "https://www.niams.nih.gov/health-topics/psoriatic-arthritis",
    "https://rheumatology.org/psoriatic-arthritis-guideline",
  ];
  const giantCell = [
    "https://www.hopkinsvasculitis.org/types-vasculitis/giant-cell-arteritis/",
    "https://www.vasculitisfoundation.org/education/vasculitis-types/giant-cell-arteritis/",
    "https://www.aao.org/eye-health/diseases/what-is-giant-cell-arteritis",
    "https://www.mayoclinic.org/diseases-conditions/giant-cell-arteritis/symptoms-causes/syc-20372758",
  ];
  const polymyalgiaRheumatica = [
    "https://www.mayoclinic.org/diseases-conditions/polymyalgia-rheumatica/symptoms-causes/syc-20376539",
    "https://my.clevelandclinic.org/health/diseases/25215-polymyalgia-rheumatica",
    "https://www.arthritis.org/diseases/polymyalgia-rheumatica",
    "https://rheumatology.org/patients/polymyalgia-rheumatica",
  ];
  const behcets = [
    "https://www.hopkinsvasculitis.org/types-vasculitis/behcets-disease/",
    "https://www.mayoclinic.org/diseases-conditions/behcets-disease/symptoms-causes/syc-20351326",
    "https://www.ninds.nih.gov/health-information/disorders/behcets-disease",
    "https://my.clevelandclinic.org/health/diseases/12980-behcets-disease",
  ];
  const ankylosingSpondylitis = [
    "https://www.mayoclinic.org/diseases-conditions/ankylosing-spondylitis/symptoms-causes/syc-20354808",
    "https://my.clevelandclinic.org/health/diseases/ankylosing-spondylitis",
    "https://www.hopkinsmedicine.org/health/conditions-and-diseases/ankylosing-spondylitis",
    "https://www.niams.nih.gov/health-topics/ankylosing-spondylitis",
    "https://www.arthritis.org/diseases/ankylosing-spondylitis",
  ];
  const osteoporosis = [
    "https://www.mayoclinic.org/diseases-conditions/osteoporosis/symptoms-causes/syc-20351968",
    "https://my.clevelandclinic.org/health/diseases/4443-osteoporosis",
    "https://www.niams.nih.gov/health-topics/osteoporosis",
    "https://www.hopkinsmedicine.org/health/conditions-and-diseases/osteoporosis",
    "https://www.nia.nih.gov/health/osteoporosis/osteoporosis",
  ];
  const lupus = [
    "https://www.mayoclinic.org/diseases-conditions/lupus/symptoms-causes/syc-20365789",
    "https://www.lupus.org/resources/what-is-lupus",
    "https://www.cdc.gov/lupus/basics/index.html",
    "https://my.clevelandclinic.org/health/diseases/4875-lupus",
  ];
  const gout = [
    "https://www.mayoclinic.org/diseases-conditions/gout/symptoms-causes/syc-20372897",
    "https://my.clevelandclinic.org/health/diseases/4755-gout",
    "https://www.hopkinsmedicine.org/health/conditions-and-diseases/gout",
    "https://www.cdc.gov/arthritis/types/gout.html",
    "https://www.niams.nih.gov/health-topics/gout",
  ];
  const pseudogout = [
    "https://www.mayoclinic.org/diseases-conditions/pseudogout/symptoms-causes/syc-20376983",
    "https://my.clevelandclinic.org/health/diseases/4756-calcium-pyrophosphate-dihydrate-deposition-disease-cppd-or-pseudogout",
    "https://www.arthritis.org/diseases/calcium-pyrophosphate-deposition",
  ];
  const iritis = [
    "https://www.mayoclinic.org/diseases-conditions/iritis/symptoms-causes/syc-20354961",
    "https://www.cedars-sinai.org/health-library/diseases-and-conditions/i/iritis.html",
    "https://www.visioncenter.org/conditions/iritis/",
    "https://iritis.org/iritis-symptoms/",
    "https://myvision.org/eye-conditions/iritis/",
  ];
  const sjogrensSyndrome = [
    "https://www.mayoclinic.org/diseases-conditions/sjogrens-syndrome/symptoms-causes/syc-20353216",
    "https://my.clevelandclinic.org/health/diseases/4929-sjogrens-syndrome",
    "https://www.niams.nih.gov/health-topics/sjogrens-syndrome/basics/symptoms-causes",
    "https://www.ninds.nih.gov/health-information/disorders/sjogrens-syndrome",
  ];
  const erosiveOsteoarthritis = [
    "https://www.the-rheumatologist.org/article/what-to-do-with-erosive-inflammatory-osteoarthritis/",
    "https://creakyjoints.org/about-arthritis/osteoarthritis/oa-overview/what-is-erosive-osteoarthritis/",
    "https://www.arthritis.org/diseases/more-about/what-is-inflammatory-osteoarthritis",
    "https://connectedrheumatology.com/understanding-erosive-osteoarthritis/",
  ];
  const ancaVasculitis = [
    "https://www.mayoclinic.org/diseases-conditions/vasculitis/diagnosis-treatment/drc-20363485",
    "https://www.vasculitisfoundation.org/education/general-vasculitis/",
  ];
  const interstitialLungDisease = [
    "https://www.mayoclinic.org/diseases-conditions/interstitial-lung-disease/symptoms-causes/syc-20353108",
    "https://my.clevelandclinic.org/health/diseases/17809-interstitial-lung-disease",
    "https://www.lung.org/lung-health-diseases/lung-disease-lookup/interstitial-lung-disease",
    "https://www.hopkinsmedicine.org/health/conditions-and-diseases/interstitial-lung-disease-pulmonary-fibrosis",
  ];
  const scleroderma = [
    "https://www.mayoclinic.org/diseases-conditions/scleroderma/symptoms-causes/syc-20351952",
    "https://my.clevelandclinic.org/health/diseases/scleroderma",
    "https://www.niams.nih.gov/health-topics/scleroderma",
    "https://www.hopkinsscleroderma.org/scleroderma/",
    "https://scleroderma.org/what-is-scleroderma/",
  ];
  const enteropathicArthritis = [
    "https://my.clevelandclinic.org/health/diseases/23166-enteropathic-arthritis",
    "https://spondylitis.org/about-spondylitis/overview-of-spondyloarthritis/enteropathic-arthritis/",
  ];
  const spondyloarthritisFamily = [
    "https://www.arthritis.org/diseases/spondyloarthritis",
    "https://my.clevelandclinic.org/health/diseases/24843-axial-spondyloarthritis",
    "https://www.hopkinsarthritis.org/ask-the-expert/what-is-spondyloarthropathy/",
    "https://dermnetnz.org/topics/spondyloarthropathy",
  ];
  return (
    <section className="subpage">
      <article className="subpage-content">
        <header>
          <h1>DISEASE INFORMATION</h1>
        </header>
        <div className="disease-list">
          <h3>Rheumatoid Arthritis</h3>
          <ul>
            {rheumatoid.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Psoriatic Arthritis</h3>
          <ul>
            {psoriatic.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Giant Cell Arteritis</h3>
          <ul>
            {giantCell.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Polymyalgia Rheumatica</h3>
          <ul>
            {polymyalgiaRheumatica.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Behcets Disease</h3>
          <ul>
            {behcets.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Ankylosing Spondylitis</h3>
          <ul>
            {ankylosingSpondylitis.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Osteoporosis</h3>
          <ul>
            {osteoporosis.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Lupus</h3>
          <ul>
            {lupus.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Gout</h3>
          <ul>
            {gout.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Pseudogout (CCPD)</h3>
          <ul>
            {pseudogout.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Iritis</h3>
          <ul>
            {iritis.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Sjogren's Syndrome</h3>
          <ul>
            {sjogrensSyndrome.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Erosive Osteoarthritis</h3>
          <ul>
            {erosiveOsteoarthritis.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Anca Vasculitis</h3>
          <ul>
            {ancaVasculitis.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Interstitial Lung Disease</h3>
          <ul>
            {interstitialLungDisease.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Scleroderma</h3>
          <ul>
            {scleroderma.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Enteropathic Arthritis</h3>
          <ul>
            {enteropathicArthritis.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
          <h3>Spondyloarthritis Family</h3>
          <ul>
            {spondyloarthritisFamily.map((link, i) => (
              <li>
                <a href="link" key={i}>
                  {link}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <a
          href="/nky-arthritis-roi.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="bring-form-btn"
        >
          Release of Information Form
        </a>
      </article>
    </section>
  );
};

export default DiseaseInformation;
